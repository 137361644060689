import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
export const StockBadge = ({ name, stock, city }) => {
	const getColor = (stock) => {
		let color = "";
		switch (stock) {
			case "EN STOCK":
				color = "bg-lupa-green";
				break;
			case "SIN STOCK":
				color = "bg-lupa-red";
				break;

			case "SÍ":
				color = "bg-lupa-green";
				break;
			case "NO":
				color = "bg-lupa-red";
				break;
			case "Pte":
				color = "bg-lupa-orange";
				break;
			case "C":
				color = "bg-lupa-yellow";
				break;
			default:
				color = "";
				break;
		}
		return color;
	};
	return (
		<OverlayTrigger
			placement={"top"}
			overlay={<Tooltip>{`Stock en ${city}: ${stock}`}</Tooltip>}
		>
			<div className='stock-button'>
				{name}
				<span className={`${getColor(stock)} stock-button-badge`}>
					{stock}
				</span>
			</div>
		</OverlayTrigger>
	);
};
