import { LOGIN, LOGOUT } from "../actions/authActions";

const expirationDate = localStorage.getItem("jwt-expiration");
const date = new Date(parseInt(expirationDate) * 1000);
const today = new Date();

const initState = localStorage.getItem("jwt") ? date >= today : false;

const authReducer = (state = initState, action) => {
  if (action.type === LOGIN) {
    return true;
  }

  if (action.type === LOGOUT) {
    localStorage.removeItem("jwt");
    localStorage.removeItem("jwt-expiration");
    return false;
  }

  return initState;
};

export default authReducer;
