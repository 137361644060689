// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAwZZrhw8z3qOvMsCgJYP1E-71gWx28bjA",
  authDomain: "appando-5db88.firebaseapp.com",
  projectId: "appando-5db88",
  storageBucket: "appando-5db88.appspot.com",
  messagingSenderId: "825463823698",
  appId: "1:825463823698:web:3c05259c2700e5aee3ae8b",
  measurementId: "G-RBL093QLHJ"
};


// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const ga = (event_name, data) => {
  console.log('logEvent', event_name, { value: data });
  logEvent(analytics, event_name, { value: data });
}