import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { applyMiddleware, createStore } from "redux";
import { load, save } from "redux-localstorage-simple";

import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { ToastProvider } from "react-toast-notifications/dist/ToastProvider";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./redux/reducers/rootReducer";
import thunk from "redux-thunk";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(
    applyMiddleware(thunk, save({ ignoreStates: ["productData"] }))
  )
);

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider>
      <App />
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    if (registration && registration.waiting) {
      await registration.unregister();
      window.location.reload();
    }
  },
});
