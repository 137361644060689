import React, { useEffect, useState } from "react";
import { getProfileDataService } from "../../services/api";
export const ProfileCard = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    const getUserData = async () => {
      let res = await getProfileDataService();
      let json = await res.json();
      if (res.status === 200) {
        setName(json.data.name);
        setEmail(json.data.username);
      }
    };
    getUserData();
  }, []);

  return (
    <div className="profile-card text-center">
      <div className="profile-card__image">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/profile-big.png"}
          className="img-fluid"
          alt=""
        />
      </div>
      <div className="profile-card__content">
        <p className="name">
          {name} <span className="id">{email}</span>
        </p>
      </div>
    </div>
  );
};
