import {
  ADD_ALL_TO_WISHLIST,
  ADD_TO_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
  DELETE_FROM_WISHLIST,
} from "../actions/wishlistActions";

import axios from "axios";

const initState = [];

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state,
    product = action.payload;
  if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      (item) => parseInt(item.id) === parseInt(product.id)
    )[0];
    fetch( process.env.REACT_APP_API_URL + `/favorites?sku=${product.sku}`,{
      method:'PATCH',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      }
    }).then(res=>console.log(res))

    if (wishlistItem === undefined) {
      return [...wishlistItems, product];
    } else {
      return wishlistItems;
    }
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + `/favorites?sku=${product.sku}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
      return wishlistItems.filter(
        (wishlistItem) => wishlistItem.id !== product.id
      );
    };
    return remainingItems(wishlistItems, product);
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    return wishlistItems.filter((item) => {
      return false;
    });
  }

  if (action.type === ADD_ALL_TO_WISHLIST) {
    return action.payload;
  }

  return wishlistItems;
};

export default wishlistReducer;
