import { getProfileDataService } from "./api";

const API_URL = process.env.API_BASE_URL;

export const getServiceWorkerRegistration = async () => {
  let ServiceWorkerContainer = navigator.serviceWorker;
  if (ServiceWorkerContainer == undefined) {
    return false;
  }
  return await ServiceWorkerContainer.getRegistration();
};
export const getPushManagerSubscriptionsService = async () => {
  let ServiceWorkerContainer = navigator.serviceWorker;
  if (ServiceWorkerContainer == undefined) {
    return false;
  }
  let swReg = await ServiceWorkerContainer.getRegistration();
  if (swReg) {
    return await swReg.pushManager.getSubscription();
  } else {
    return null;
  }
};
export function getPublicKey() {
  return fetch(API_URL + "/api/push-notifications/key")
    .then((res) => res.arrayBuffer())
    .then((key) => new Uint8Array(key));
}
export async function suscribeNotificationsService() {
  let ServiceWorkerContainer = navigator.serviceWorker;
  if (ServiceWorkerContainer == undefined) {
    return false;
  }
  let swReg = await ServiceWorkerContainer.getRegistration();

  if (!swReg) {
    console.log("No hay registro de SW");
    return false;
  }

  let key = await getPublicKey();
  let response = await swReg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: key,
  });
  let subscription = response.toJSON();
  let profileResponse = await getProfileDataService();
  let profileData = await profileResponse.json();
  let data = { subscription: subscription, user_id: profileData.data.id };

  try {
    let response = await fetch(API_URL + "/api/push-notifications/subscribe", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    let status = response.status;
    if (status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}
export async function cancelSubscriptionService() {
  let ServiceWorkerContainer = navigator.serviceWorker;
  let swReg = await ServiceWorkerContainer.getRegistration();
  if (swReg) {
    let subs = await swReg.pushManager.getSubscription();
    console.log(subs);
    if (subs) {
      subs.unsubscribe();
    }
  }
}
export async function getPushManagerPermissionState() {
  let ServiceWorkerContainer = navigator.serviceWorker;
  let swReg = await ServiceWorkerContainer.getRegistration();
  if (swReg) {
    let key = await getPublicKey();
    let permisionState = await swReg.pushManager.permissionState({
      userVisibleOnly: true,
      applicationServerKey: key,
    });
    console.log(permisionState);
    return permisionState; //'prompt', 'denied', 'granted'
  }
}
export function beforeInstallPrompt() {
  let deferredPrompt;
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA

    /* showInstallPromotion(); */
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
  return deferredPrompt;
}
export function getPWADisplayMode() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  if (document.referrer.startsWith("android-app://")) {
    return "twa";
  } else if (navigator.standalone || isStandalone) {
    return "standalone";
  }
  return "browser";
}
export async function getInstalledRelatedApps() {
  let installed_apps = {};
  if ("getInstalledRelatedApps" in window.navigator) {
    const relatedApps = await navigator.getInstalledRelatedApps();
    console.log(relatedApps);
    relatedApps.forEach((app) => {
      //if your PWA exists in the array it is installed
      installed_apps[app.platform] = app.url;
      //console.log(app.platform, app.url);
    });
  }
  return installed_apps;
}
