export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const loginSuccesfull = () => {
  return (dispatch) => {
    dispatch({
      type: LOGIN,
      payload: {},
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
};
