import { ga } from "../../firebase";

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	addToWishlistDispatch,
	deleteFromWishlistDispatch,
} from "../../redux/actions/wishlistActions";
import {
	fetchProducts,
	setSearchString,
} from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";

import { Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "../Cards/ProductCard";
import PropTypes from "prop-types";
// import Pagination from "../Pagination/Pagination.jsx";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import axios from "axios";
import { getDiscountPrice } from "../../helpers/product";
import { useEffectIf } from "../../hooks";
import { DesktopCard } from "../Cards/ProductCard/DesktopCard";
import { useWindowSize } from "../../helpers/useWindowSize";

const ProductsList = ({
	listModeActive = true,
	sortType,
	sortValue,
	limit = 0,
	showNovedades = 0,
	showOfertas = 0,
	handleShowProductDetail,
}) => {
	const [nextPage, setNextPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const lastFetchTime = useRef(0);

	const reduxState = useSelector((state) => state);
	// const products = reduxState.productData.products;
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const wishlistItems = reduxState.wishlistData;
	const searchString = reduxState.productData.searchString;

	const dispatch = useDispatch();

	const addToWishlist = (item) => {
		dispatch(addToWishlistDispatch(item));
	};

	const removeFromWishList = (item) => {
		dispatch(deleteFromWishlistDispatch(item));
	};

	// const appendMoreProductsFromAPI = (
	// 	currentProducts,
	// 	nextPage,
	// 	searchString
	// ) => {
	// 	let sort = "";
	// 	console.log(sortType);
	// 	if (sortType === "price") {
	// 		switch (sortValue) {
	// 			case "priceHighToLow":
	// 				sort = "&by_price=asc";
	// 				break;
	// 			case "priceLowToHigh":
	// 				sort = "&by_price=asc";
	// 				break;
	// 			case "skuHighToLow":
	// 				sort = "&by_abc_sku=asc";
	// 				break;
	// 			case "skuLowToHigh":
	// 				sort = "&by_abc_sku=asc";
	// 				break;
	// 			case "descHighToLow":
	// 				sort = "&by_abc=asc";
	// 				break;
	// 			case "descLowToHigh":
	// 				sort = "&by_abc=asc";
	// 				break;
	// 			default:
	// 				sort = "";
	// 		}
	// 	}
	// 	if (showNovedades) {
	// 		sort += "&novedades=1";
	// 	}
	// 	if (showOfertas) {
	// 		sort += "&ofertas=1";
	// 	}
	// 	axios
	// 		.get(
	// 			`${process.env.REACT_APP_API_URL}/products?${
	// 				searchString
	// 					? `search=${encodeURIComponent(searchString)}&`
	// 					: ""
	// 			}page=${nextPage}&limit=20${sort}&by_rotacion=desc`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			if (response.data.current_page > response.data.last_page) {
	// 				setHasMore(false);
	// 			}
	// 			const prod_ids = products.map((product) => product.id);
	// 			const filtered_products = response.data.data.filter(
	// 				(product) => !prod_ids.includes(product.id)
	// 			);
	// 			setNextPage(response.data.current_page + 1);
	// 			dispatch(
	// 				fetchProducts([...currentProducts, ...filtered_products])
	// 			);
	// 		})
	// 		.catch((error) => console.log(error));
	// };

	// const fetchMoreProducts = async () => {
	// 	if (limit > 0 && products && products.length >= limit) {
	// 		setHasMore(false);
	// 	}
	// 	await appendMoreProductsFromAPI(
	// 		products,
	// 		nextPage,
	// 		searchString,
	// 		sortType,
	// 		sortValue
	// 	);
	// };

	//Funcion que se llamara al desmontar el componente, volvera a setear la busqueda en los productos por defecto
	// const resetSearchAndProducts = useCallback(() => {
	// 	axios
	// 		.get(
	// 			process.env.REACT_APP_API_URL +
	// 				"/products?limit=20&by_rotacion=desc",
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			setNextPage(response.data.current_page + 1);
	// 			dispatch(fetchProducts(response.data.data));
	// 			dispatch(setSearchString(""));
	// 		})
	// 		.catch((error) => console.log(error));
	// }, [dispatch]);

	// useEffectIf(
	// 	"ProductsList => Reseteo la busqueda y el search de productos al desmontar",
	// 	() => {
	// 		return () => {
	// 			resetSearchAndProducts();
	// 		};
	// 	},
	// 	true,
	// 	[resetSearchAndProducts]
	// );

	//Este useEffect se disparar solo en caso de que sortValue o sortTypeCambien
	//Dependiendo de esos valores hara una nueva llamada a axios que pisara los productos anteriores
	// 	useEffectIf(
	// 		"ProductsList => Actualizo la lista de prodcutos porque cambio el ordenamiento seleccionado",
	// 		() => {
	// 			// setIsLoading(true);
	// 			setHasMore(true);

	// 			let sort = "";
	// 			console.log(sortType);
	// 			if (sortType === "price") {
	// 				switch (sortValue) {
	// 					case "priceLowToHigh":
	// 						sort = "&by_price=asc";
	// 						break;
	// 					case "priceHighToLow":
	// 						sort = "&by_price=asc";
	// 						break;
	// 					case "skuLowToHigh":
	// 						sort = "&by_abc_sku=asc";
	// 						break;
	// 					case "skuHighToLow":
	// 						sort = "&by_abc_sku=asc";
	// 						break;
	// 					case "descLowToHigh":
	// 						sort = "&by_abc=asc";
	// 						break;
	// 					case "descHighToLow":
	// 						sort = "&by_abc=asc";
	// 						break;
	// 					default:
	// 						sort = "";
	// 				}
	// 			}
	// 			if (showNovedades) {
	// 				sort += "&novedades=1";
	// 			}
	// 			if (showOfertas) {
	// 				sort += "&ofertas=1";
	// 			}
	// 			let registro = Date.now();
	// 			lastFetchTime.current = registro;

	// 			// logEvent(analytics, 'search', searchString);
	// 			ga("search", searchString);

	// 			axios
	// 				.get(
	// 					`${process.env.REACT_APP_API_URL}/products?${
	// 						searchString
	// 							? `search=${encodeURIComponent(searchString)}&`
	// 							: ""
	// 					}page=1&limit=20${sort}&by_rotacion=desc`,
	// 					{
	// 						headers: {
	// 							Authorization: `Bearer ${localStorage.getItem(
	// 								"jwt"
	// 							)}`,
	// 						},
	// 					}
	// 				)
	// 				.then((response) => {
	// 					if (lastFetchTime.current === registro) {
	// 						dispatch(fetchProducts(response.data.data));
	// 					}
	// 					setNextPage(2);
	// 					// setIsLoading(false);
	// 					if (response.data.data.length === 0) {
	// 						setHasMore(false);
	// 					}
	// 				})
	// 				.catch((error) => console.log(error));
	// 		},
	// 		true,
	// 		[
	// 			sortValue,
	// 			sortType,
	// 			dispatch,
	// 			searchString,
	// 			showOfertas,
	// 			showNovedades,
	// 		]
	// 	);

	// 	//Cuando cambie el string de busqueda, tambien debemos resetear la nextPage
	// 	/*   useEffectIf(
	//     "ProductsList => Cambia el string de busqueda, cambio la nextPage",
	//     () => {
	//       setNextPage(2);
	//     },
	//     nextPage !== 2 && !!searchString,
	//     [searchString],
	//     { noFirst: true }
	//   );

	const windowSize = useWindowSize();

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		if (searchString !== "") {
			const searchWords = searchString
				?.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toUpperCase()
				.split(" ");

			const searchedProducts = products.filter((product) => {
				const code = product?.code
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				const model = JSON.parse(product.extra_data)
					?.modelo.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				const brand = product?.brand
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase();

				return searchWords?.every(
					(word) =>
						code.includes(word) ||
						model.includes(word) ||
						brand.includes(word)
				);
			});
			setFilteredProducts(searchedProducts);
		} else {
			setFilteredProducts(products);
		}
	}, [searchString, products]);

	useEffect(() => {
		setFilteredProducts(products);
	}, [products]);

	const getProducts = () => {
		setIsLoading(true);
		axios
			.get(process.env.PUBLIC_URL + "/data/products.json")
			.then((response) => {
				setProducts(response.data.articles);
			})
			.catch((error) => console.log(error))
			.finally(() => setIsLoading(false));
	};

	// const handlePageChange = useCallback(() => {}, [second]);

	return (
		<div className='shop-products-area'>
			{/* products list with infinite scroll */}
			<div
				className={`shop-grid-products-wrapper space-mb-m--20 d-block`}
			>
				{!isLoading ? (
					<InfiniteScroll
						dataLength={
							filteredProducts ? filteredProducts.length : 0
						}
						// next={fetchMoreProducts}
						// hasMore={hasMore}
						loader={
							// !isLoading && hasMore ? (
							// 	<h4
							// 		className='mt-5 text-center text-muted'
							// 		style={{ marginBottom: "5rem" }}
							// 	>
							// 		Cargando...
							// 	</h4>
							// ) : (
							// 	""
							// )
							filteredProducts.length === 0 ? (
								<h4
									className='mt-5 text-center text-muted'
									style={{ marginBottom: "5rem" }}
								>
									Cargando...
								</h4>
							) : (
								""
							)
						}
						endMessage={
							<h6 className='mt-5 text-center text-muted'>
								{filteredProducts.length > 0
									? "Ya los viste todos."
									: "No se encontraron productos"}
							</h6>
						}
						style={{ overflow: "hidden" }}
					>
						<div className='container'>
							<div className='row row-10'>
								{/*               {listModeActive ? ( */}
								{windowSize.width < 800 ? (
									<div>
										{filteredProducts &&
											filteredProducts.map((product) => {
												const wishlistItem =
													wishlistItems.filter(
														(wishlistItem) =>
															wishlistItem.id ===
															product.id
													)[0];

												return (
													<ProductCard
														removeFromWishList={
															removeFromWishList
														}
														product={product}
														getDiscountPrice={
															getDiscountPrice
														}
														wishlistItem={
															wishlistItem
														}
														addToWishlist={
															addToWishlist
														}
														key={product.id}
														horizontal={
															listModeActive
														}
														handleShowProductDetail={
															handleShowProductDetail
														}
													/>
												);
											})}
									</div>
								) : (
									<div
										style={{
											display: "flex",
											flexWrap: "wrap",
											justifyContent: "center",
										}}
									>
										{filteredProducts?.length > 0 &&
											filteredProducts.map((product) => {
												const wishlistItem =
													wishlistItems.filter(
														(wishlistItem) =>
															wishlistItem.id ===
															product.id
													)[0];

												return (
													<DesktopCard
														removeFromWishList={
															removeFromWishList
														}
														product={product}
														getDiscountPrice={
															getDiscountPrice
														}
														wishlistItem={
															wishlistItem
														}
														addToWishlist={
															addToWishlist
														}
														key={product.id}
														horizontal={
															listModeActive
														}
														handleShowProductDetail={
															handleShowProductDetail
														}
													/>
												);
											})}
									</div>
								)}

								{/*               ) : (
                products &&
                products.map((product) => {
                  const wishlistItem = wishlistItems.filter(
                    (wishlistItem) => wishlistItem.id === product.id
                  )[0];
                  return (
                    <ProductCard
                      removeFromWishList={removeFromWishList}
                      product={product}
                      getDiscountPrice={getDiscountPrice}
                      wishlistItem={wishlistItem}
                      addToWishlist={addToWishlist}
                      key={product.id}
                      horizontal={listModeActive}
                    />
                  );
                })
              )} */}
							</div>
						</div>
					</InfiniteScroll>
				) : (
					<h4 className='mt-5 text-center text-muted'>Cargando...</h4>
				)}
			</div>
			{/* <Pagination
				currentPage={currentPage}
				totalPages={totalPages}
				handlePageChange={handlePageChange}
			/> */}
			<ScrollToTopButton />
		</div>
	);
};

ProductsList.propTypes = {
	/** ListModeACtive define si la lista de productos se vera como lista (true) o como mozaico (false) */
	listModeActive: PropTypes.bool,
	/** sortType es el tipo de ordenacion que utilizaremos */
	sortType: PropTypes.string,
	/** sortValue es el valor que recibira la ordenacion */
	sortValue: PropTypes.string,
	/** limit es el limite que etableceremos a la cantidad de productos que puede mostrar esta instancia de ProductsList */
	limit: PropTypes.number,
};
export default ProductsList;
