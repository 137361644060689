export function formatProductPrice(number) {
  let formatted = new Intl.NumberFormat("de-DE").format(Math.ceil(number));
  let formated_with_sign = "$ " + formatted;
  return formated_with_sign;
}
export const extractMarcaFrom = (marca) => {
  return marca.slice(0, marca.indexOf("#"));
};
export const formatwithElipsis = (string, length = 15) => {
  return string.slice(0, length) + "...";
};
export const formatwithElipsisLong = (string) => {
  return string.slice(0, 66) + "...";
};
export const formatDateFromString = (string) => {
  let date = new Date(string);
  let formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  return formattedDate;
};
export const discardAfterFirstComma = (string = "-") => {
  return string.indexOf(",") > 0
    ? string.slice(0, string.indexOf(","))
    : string;
};
