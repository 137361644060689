import React from "react";
import { suscribeNotificationsService } from "../../services/pushNotificationService";

export const NotificationAlert = ({ setShowNotificationsAlert }) => {
  const handleSubscription = async () => {
    let res = await suscribeNotificationsService();
    if (res) {
      setShowNotificationsAlert(false);
    }
  };
  const handleRejectSubscription = () => {
    localStorage.setItem("no-notification-record", true);
    document.body.classList.remove("modal-open-notification");
    setShowNotificationsAlert(false);
  };
  const handlePostponeSubscription = () => {
    let date = Date.now();
    localStorage.setItem("no-notification-record-date", JSON.stringify(date));
    document.body.classList.remove("modal-open-notification");
    setShowNotificationsAlert(false);
  };
  return (
    <div className="notification-alert-wrapper">
      <div
        className="notification-alert-background"
        onClick={handlePostponeSubscription}
      ></div>
      <div className="notification-alert-container">
        <div className="notification-alert-title">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo-head-distri.png"}
            alt="distrisuper"
          />
        </div>
        <p className="notification-alert-message">
          ¿Querés suscribirte a notificaciones con novedades y avisos?
        </p>
        <div className="notification-alert-cta-container">
          <button
            className="notification-alert-cta-reject"
            onClick={handleRejectSubscription}
          >
            No, gracias
          </button>
          <button
            className="notification-alert-cta-accept"
            onClick={handleSubscription}
          >
            Sí, suscribirme
          </button>
        </div>
      </div>
    </div>
  );
};
