import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { FiChevronUp } from "react-icons/fi/index";

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return visible ? (
    <Button
      className="p-1"
      style={{
        border: "1px solid #FFF",
        backgroundColor: `rgba(44, 48, 143, .6)`,
        position: "fixed",
        bottom: "4rem",
        right: "1rem",
        width: "40px",
        height: "40px",
        borderRadius: "100%",
      }}
      onClick={scrollToTop}
    >
      <FiChevronUp size={ 30 } />
    </Button>
  ) : (
    <></>
  );
};

export default ScrollToTopButton;
