import { ga } from '../../firebase';

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const ADD_ALL_TO_WISHLIST = "ADD_ALL_TO_WISHLIST";

// add to wishlist
export const addToWishlistDispatch = (item) => {

  ga('add_to_wishlist', item.id);

  return (dispatch) => {
    dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

// delete from wishlist
export const deleteFromWishlistDispatch = (item, addToast) => {  
  return (dispatch) => {
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlistDispatch = (addToast) => {
  return (dispatch) => {
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};

//add a list of products to wishlist
export const fetchAllFromWishList = (items) => {
  return (dispatch) => {
    dispatch({ type: ADD_ALL_TO_WISHLIST, payload: items });
  };
};
