import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { getProductCartQuantity } from "../../helpers/product";
import { useSelector } from "react-redux";
import { NotificationAlert } from "../Footer/NotificationAlert";
import {
	getPushManagerSubscriptionsService,
	getServiceWorkerRegistration,
} from "../../services/pushNotificationService";
import { getProfileDataService } from "../../services/api";

const HeaderMenu = () => {
	const [showNotificationsAlert, setShowNotificationsAlert] = useState(false);
	const [userID, setUserID] = useState(5);
	const [currentPath, setCurrentPath] = useState("");
	let location = useLocation();
	useEffect(() => {
		setCurrentPath(location.pathname);
		console.log(location.pathname);
	}, [location.pathname]);
	useEffect(() => {
		//ONLY TO TEST WITH SERGIOS'S USER
		const getUserData = async () => {
			let res = await getProfileDataService();
			let json = await res.json();
			if (res.status === 200) {
				return setUserID(json.data.id);
			} else {
				setUserID(5);
			}
		};
		getUserData();
		//ONLY TO TEST WITH SERGIOS'S USER

		getServiceWorkerRegistration().then((sw) => {
			if (sw) {
				getPushManagerSubscriptionsService().then((subs) => {
					console.log("subs", subs);
					let local_config = localStorage.getItem(
						"no-notification-record"
					);
					let local_config_date = localStorage.getItem(
						"no-notification-record-date"
					);
					let isToday = false;
					if (local_config_date) {
						let date = new Date(Number(local_config_date));
						const today = new Date();
						isToday =
							date.getDate() == today.getDate() &&
							date.getMonth() == today.getMonth() &&
							date.getFullYear() == today.getFullYear();
					}

					console.log(isToday);
					if (!subs && !local_config && !isToday && userID === 36) {
						setTimeout(() => {
							document.body.classList.add(
								"modal-open-notification"
							);
							setShowNotificationsAlert(true);
						}, 10000);
					} else {
						console.log(
							"Alerta para suscribirse a las notificaciones cancelada por el usuario"
						);
					}
				});
			}
		});
	}, [userID]);
	const cartData = useSelector((state) => state.cartData);

	let cartItemsQuantity = 0;

	cartData.forEach((item) => {
		cartItemsQuantity += getProductCartQuantity(cartData, item);
	});

	return (
		<div className='header-menu-wrapper'>
			<ul className='header-menu'>
				<li>
					<NavLink
						to={process.env.PUBLIC_URL + "/"}
						className={`footer-nav-single footer-nav-single--styleTwo ${
							currentPath === "/" ? "footer-nav-selected" : ""
						}`}
						replace={true}
					>
						<div className='menu-wrapper'>
							<ReactSVG
								src={
									process.env.PUBLIC_URL +
									"/assets/img/icons/home.svg"
								}
							/>
							<div>Home</div>
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={process.env.PUBLIC_URL + "/cart"}
						className={`footer-nav-single footer-nav-single--styleTwo ${
							cartData.length > 0
								? "footer-nav-single--cartActive"
								: ""
						} ${
							currentPath === "/cart" ? "footer-nav-selected" : ""
						}`}
						replace={location.pathname != "/" ? true : false}
					>
						<div className='menu-wrapper'>
							{cartData.length > 0 && (
								<Badge className='menu-cart--badge'>
									{cartItemsQuantity}
								</Badge>
							)}
							<ReactSVG
								src={
									process.env.PUBLIC_URL +
									"/assets/img/icons/cart.svg"
								}
							/>
							<div>Carrito</div>
						</div>
					</NavLink>
				</li>
				{/* <li>
					<NavLink
						to={process.env.PUBLIC_URL + "/Contact"}
						className={`footer-nav-single footer-nav-single--styleTwo ${
							currentPath === "/Contact"
								? "footer-nav-selected"
								: ""
						}`}
						replace={location.pathname != "/" ? true : false}
					>
						<div className='menu-wrapper text-center'>
							<ReactSVG
								src={
									process.env.PUBLIC_URL +
									"/assets/img/icons/contact-mail-svgrepo-com.svg"
								}
							/>
							<div>Contacto</div>
						</div>
					</NavLink>
				</li> */}
				<li>
					<NavLink
						to={{
							pathname:
								"https://api.whatsapp.com/send?phone=5491131526194",
						}}
						target='_blank'
						className={`footer-nav-single footer-nav-single--styleTwo`}
					>
						<div className='menu-wrapper' id='wapp'>
							<ReactSVG
								src={
									process.env.PUBLIC_URL +
									"/assets/img/icons/whatsapp-social-media-svgrepo-com.svg"
								}
							/>
							<div className='wapp'>WhatsApp</div>
						</div>
					</NavLink>
				</li>
			</ul>

			{showNotificationsAlert && (
				<NotificationAlert
					setShowNotificationsAlert={setShowNotificationsAlert}
				/>
			)}
		</div>
	);
};

export default HeaderMenu;
