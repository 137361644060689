export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const PRODUCTS_SEARCH = "PRODUCTS_SEARCH";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

const setProductsSearch = (search) => ({
  type: PRODUCTS_SEARCH,
  payload: search,
});

// fetch products
export const fetchProducts = (products) => {
  return (dispatch) => {
    dispatch(fetchProductsSuccess(products));
  };
};

// set search string
export const setSearchString = (search) => {
  return (dispatch) => {
    dispatch(setProductsSearch(search));
  };
};
