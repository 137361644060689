import React from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

const AppRoute = ({
  component: Component,
  layout: Layout,
  layoutProp,
  isPrivate = false,
  ...rest
}) => {
  const { loggedIn } = useSelector((state) => state);

  return isPrivate ? (
    loggedIn ? (
      <Route
        {...rest}
        render={(props) => (
          <Layout {...layoutProp}>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : (
      <Redirect to="/login"></Redirect>
    )
  ) : (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...layoutProp}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
