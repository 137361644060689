import React, { useEffect } from "react";
import { name, version } from "../../../package.json";
import { FiX } from "react-icons/fi/index";
import { BsDoorOpen } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { LogoutButton } from "./LogoutButton";
import { ProfileCard } from "./ProfileCard";
export default ({ show, activeStatus, handleClickOffcanvas }) => {
  let location = useLocation();
  useEffect(() => {
    const offcanvasNavigations = document.querySelectorAll(
      ".offcanvas-navigation > li > span "
    );
    offcanvasNavigations.forEach((single) => {
      single.addEventListener("click", () => activeStatus(!show));
    });
  }, []);

  return (
    <div className={`offcanvas-bg ${show ? "active" : ""}`}>
      <div className={`offcanvas-menu ${show ? "active" : ""}`}>
        <div
          className="offcanvas-close-container"
          onClick={() => activeStatus(!show)}
        >
          <FiX />
        </div>
        <ProfileCard />
        <div className="offcanvas-navigation-wrapper">
          <ul
            className="offcanvas-navigation"
            onClick={() => activeStatus(!show)}
          >
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/cart-three.svg"
                  }
                />
              </span>
              <Link
                to={process.env.PUBLIC_URL + "/cart"}
                replace={location.pathname != "/" ? true : false}
              >
                Mi Pedido
              </Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/cart-two.svg"
                  }
                />
              </span>
              <Link
                to={process.env.PUBLIC_URL + "/order"}
                replace={location.pathname != "/" ? true : false}
              >
                Mis Pedidos
              </Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/profile-two.svg"
                  }
                />
              </span>
              <Link
                to={process.env.PUBLIC_URL + "/profile"}
                replace={location.pathname != "/" ? true : false}
              >
                Mi Perfil
              </Link>
            </li>

            <li>
              <span className="icon">
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
                />
              </span>
              <Link
                to={process.env.PUBLIC_URL + "/contact"}
                replace={location.pathname != "/" ? true : false}
              >
                Contacto
              </Link>
            </li>
            <li className="d-flex justify-content-start">
              <span className="icon">
                <BsDoorOpen size={11} className="text-danger" />
                {/* <FiX size={11} className="text-danger" /> */}
              </span>
              <LogoutButton />
            </li>
          </ul>
        </div>
        {/* <AekoFooter /> */}
      </div>

      <div
        onClick={() => activeStatus(!show)}
        className={`offcanvas-bg ${show ? "active" : ""}`}
      ></div>
    </div>
  );
};

function AekoFooter() {
  return (
    <div className="aeko-footer">
      <div className="aeko-footer-version">Versión {version}</div>

      <a href="https://aeko.com.ar" target="_blank">
        {" "}
        <span>Desarrollado por</span>
        <img
          className="aeko-footer-logo"
          src={process.env.PUBLIC_URL + "/assets/aeko.svg"}
        />
      </a>
    </div>
  );
}
