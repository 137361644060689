import {
	FETCH_PRODUCTS_SUCCESS,
	PRODUCTS_SEARCH,
} from "../actions/productActions";
const initState = { products: [] };

const productReducer = (state = initState, action) => {
	if (action.type === FETCH_PRODUCTS_SUCCESS) {
		return {
			...state,
			products:
				/* action.payload.filter((newItem) =>
        state.products.find(
          (itemState) => parseInt(itemState.id) === parseInt(newItem.id)
        )
      ), */ action.payload,
		};
	}
	if (action.type === PRODUCTS_SEARCH) {
		return {
			...state,
			searchString: action.payload,
		};
	}

	return state;
};

export default productReducer;
