import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import { clear } from "redux-localstorage-simple";
import { deleteAllFromCartDispatch } from "../../redux/actions/cartActions";
import { deleteAllFromWishlistDispatch} from "../../redux/actions/wishlistActions";
import React from "react";
export const LogoutButton = ()=>{
    const dispatch = useDispatch()

    const handleLogout = ()=>{
        dispatch(deleteAllFromCartDispatch());
        dispatch(deleteAllFromWishlistDispatch())
        dispatch(logout());
        
    }
    return  (<span
    onClick={handleLogout}
    className="text-danger"
    role="button"
  >
    Cerrar Sesion
  </span>)



}